<template>
  <div class="container position-relative">
    <!-- begin::Legacy device alert -->
    <template v-if="equipment.isDeviceLinked() && device.isLegacyDevice()">
      <div class="loading-container loading-overlap m-n5 h-auto">
        <div class="loading-backdrop rounded opacity-20" />
        <div class="loading-block">
          <div class="alert alert-custom alert-light-info ml-auto mr-auto w-100 p-3 d-block">
            <div
              class="alert-text"
              v-html="$t('sunbeds.legacy_device_warning')"
            />
          </div>
        </div>
      </div>
    </template>
    <!-- end::Legacy device alert -->

    <!-- begin::Software version information -->
    <div class="row border-bottom pb-3 position-relative">
      <!-- begin::Loading -->
      <template v-if="isMounted === false || update.isLoading">
        <div class="loading-container loading-overlap">
          <div class="loading-backdrop rounded" />
          <div class="loading-block">
            <div class="blockui ml-auto mr-auto w-100">
              <span class="float-left">Gathering data...</span>
              <span class="spinner spinner-primary ml-auto" />
            </div>
          </div>
        </div>
      </template>
      <!-- end::Loading -->

      <!-- begin::Software version labels -->
      <div class="col-12 col-md-8 offset-md-2">
        <div class="row text-center">
          <!-- begin::Installed software version -->
          <div class="col form-group">
            <label class="text-upper">Software version</label>
            <div>
              <span
                :class="[
                  'label w-150px label-inline label-xl label-rounded p-5',
                  (update.release && update.release.version ?
                    (update.latest && update.latest._meta.guid === update.release.hash ?
                      'label-light-success'
                      : 'label-light-info'
                    )
                    : 'label-secondary'
                  )
                ]"
              >
                {{ (update.release && update.release.version) || 'Unknown' }}
              </span>
            </div>
          </div>
          <!-- end::Installed software version -->

          <!-- begin::Pinned software version -->
          <div v-if="$auth.hasPermission('equipment.controller.update.pin')" class="col form-group">
            <label class="text-upper">Pinned version</label>
            <div>
              <span :class="[
                'label w-150px label-inline label-xl label-rounded p-5',
                (pinning.isLoading === false
                  ? pinnedSoftwareSetting !== undefined
                    ? 'label-light-info'
                    : 'label-light-success'
                  : 'label-secondary'
                )
              ]">
                {{
                  (pinnedSoftwareSetting !== undefined
                    ? pinnedSoftwareSetting !== null
                      ? pinnedSoftwareUpdate
                        ? pinnedSoftwareUpdate.version
                        : pinnedSoftwareSetting.substr(-7, 7)
                      : 'Updates disabled'
                    : 'Not pinned'
                  )
                }}
              </span>
            </div>
          </div>
          <!-- end::Pinned software version -->
        </div>
      </div>
    </div>
    <!-- end::Software version information -->

    <!-- begin::Software update -->
    <div v-if="$auth.hasPermission('equipment.controller.update')" class="row mt-3 border-bottom pb-3">
      <div class="col-12 col-md-9">
        <h5 class="overflow-ellipsis">Check for updates</h5>
        <span>Check for new updates. If a new update is available it will be installed automatically.</span>
      </div>
      <div class="col mt-3 my-md-auto text-right">
        <button
          type="button"
          :class="['btn btn-info ml-3 w-150px']"
          @click.prevent="onRegularUpdateCycle"
        >
          Check &amp; update
        </button>
      </div>
    </div>
    <!-- end::Software update -->

    <!-- begin::Software actions -->
    <div class="row mt-3 border-bottom pb-3">
      <div class="col-12 col-md-9">
        <h5 class="overflow-ellipsis">Auto update sunbed</h5>
        <span>When enabled, the MyLuxura Module will install new updates between 00:00 and 06:00.</span>
      </div>
      <div class="col mt-3 mt-md-auto mb-md-auto text-right">
        <span :class="['switch', { 'switch-success': isAutoUpdateEnabled }]">
          <label class="ml-auto">
            <input
              type="checkbox"
              name="select"
              :checked="isAutoUpdateEnabled"
              :disabled="auto_update.isLoading || $auth.hasPermission('equipment.device.settings.update') === false"
              @click.prevent="onAutoUpdateChange($event.target.checked)"
            >
            <span />
          </label>
        </span>
      </div>
    </div>
    <!-- end::Software actions -->

    <!-- begin::Pin software version -->
    <div v-if="$auth.hasPermission('equipment.controller.update.pin')" class="row mt-3 border-bottom pb-3">
      <div class="col-12 col-md-9">
        <h5 class="overflow-ellipsis">Pin software</h5>
        <span>Pin sunbed to a software version, no update or the regular update cycle.</span>
      </div>
      <div class="col mt-3 my-md-auto text-right">
        <select
          class="form-control"
          @change="onSoftwarePinChange($event.target.value)"
          :disabled="pinning.isLoading"
          :value="(pinnedSoftwareSetting !== undefined ? pinnedSoftwareSetting : 'delete')"
        >
          <optgroup label="Actions">
            <option value="delete">Not pinned</option>
            <option :value="null">Disable updates</option>
          </optgroup>

          <!-- begin::Update options -->
          <template v-if="pinning.updates && pinning.updates.length">
            <optgroup label="Updates">
              <option
                v-for="update in pinning.updates"
                :key="`equipment.${equipment._meta.guid}.updates.sunbed.${update._meta.guid}.pinning`"
                :value="update._meta.guid"
              >
                {{ `v${update.version} ${update.state !== 'published' ? `(${$tu(`updates.states.${update.state}.title`)})` : ''}` }}
              </option>
            </optgroup>
          </template>
          <!-- end::Update options -->
        </select>
      </div>
    </div>
    <!-- end::Pin software version -->

    <!-- begin::Controller update actions -->
    <template v-if="$auth.hasPermission('equipment.controller.update')">
      <div class="row mt-3">
        <div class="col-12 col-md-9">
          <h5 class="overflow-ellipsis">Full software transfer <span class="label label-sm label-warning label-inline">Caution</span></h5>
          <span>Request the module to execute a full software transfer. <strong>Use with caution.</strong></span>
        </div>
        <div class="col mt-3 my-md-auto text-right">
          <button
            type="button"
            :class="['btn btn-warning ml-3 w-150px']"
            @click.prevent="onFullSoftwareTransfer"
          >
            Request
          </button>
        </div>
      </div>
    </template>

    <div v-if="$auth.hasPermission('equipment.controller.update.custom')" class="row mt-3 border-top pt-3">
      <div class="col-12 col-md-9">
        <h5 class="overflow-ellipsis">Custom file transfer <span class="label label-sm label-danger label-inline">Caution</span></h5>
        <span>Upload custom file(s) instead of using the update system.</span>
      </div>
      <div class="col mt-3 my-md-auto text-right">
        <button
          type="button"
          :class="['btn btn-danger ml-3 w-150px']"
          @click.prevent="onCustomFileTransfer"
        >
          Custom
        </button>
      </div>
    </div>
    <!-- end::Controller update actions -->

    <!-- begin::Modals -->
    <custom-file-transfer-modal ref="customFileTransferModal" />
    <!-- end::Modals -->
  </div>
</template>

<script>
import equipmentMixin from '@/components/pages/sunbeds/view/libs/mixin';

import Equipment from '@/libs/classes/equipment';
import Update from '@/libs/classes/update';

import customFileTransferModal from './modals/custom-file-transfer.modal.vue';

export default {
  mixins: [equipmentMixin],
  components: {
    customFileTransferModal,
  },
  data () {
    return {
      isMounted: false,
      isLoading: false,

      update: {
        isLoading: false,

        latest: null,
        release: (this.equipment && this.equipment.controllerRelease) || null,
      },

      auto_update: {
        isLoading: false,
      },

      pinning: {
        isLoading: false,

        updates: [],
      },
    };
  },
  computed: {
    isAutoUpdateEnabled () {
      return this.device && this.device.settings && this.device.settings.controller.auto_update === true;
    },

    pinnedSoftwareSetting () {
      const pinnedSoftwareSetting = this.equipment && this.equipment.settings && this.equipment.settings.find((setting) => setting.key === 'updates.P10241.pin');
      if (pinnedSoftwareSetting) {
        return pinnedSoftwareSetting.payload;
      }

      return undefined;
    },
    pinnedSoftwareUpdate () {
      const value = this.pinnedSoftwareSetting;
      if (!value) {
        return null;
      }

      return (this.pinning.updates && this.pinning.updates.find((update) => update._meta.guid === value)) || null;
    },
  },
  watch: {
    $route (to) {
      if (to.name !== this.$route.name) {
        return;
      }

      this.onRoute();
    },
  },

  async mounted () {
    this.onRoute();

    // Get latest release if update release is known
    if (this.update.release) {
      this.$set(this, 'isLoading', true);
      try {
        const response = await this.$ws.get(`${Update.uri}/${this.update.release.type}/latest`);
        this.$set(this.update, 'latest', new Update().merge(response));
      } catch (e) {
        console.error(e);
      } finally {
        this.$set(this, 'isLoading', false);
      }
    }

    // When resource can pin
    // Get all updates that are in beta or published
    if (this.$auth.hasPermission('updates.get') && this.$auth.hasPermission('equipment.controller.update.pin')) {
      this.$set(this.pinning, 'isLoading', true);
      try {
        const response = await this.$ws.get(`${Update.uri}`, {
          query: {
            filter: {
              type: 'P10241',
              state: ['published', 'beta'],
            },
          },
        });

        this.$set(this.pinning, 'updates', response.map((update) => new Update().merge(update)));
      } catch (e) {
        this.$errors.handle(e, { ui_component: true });
        console.error(e);
      } finally {
        this.$set(this.pinning, 'isLoading', false);
      }
    }

    await this.$nextTick();

    this.$set(this, 'isMounted', true);
  },

  methods: {
    async onAutoUpdateChange (value) {
      if (typeof value !== 'boolean') {
        return;
      }

      // If setting auto update to true, just update it
      if (value === true) {
        this.$set(this.auto_update, 'isLoading', true);
        try {
          const response = await this.$ws.put(`${Equipment.uri}/${this.equipment._meta.guid}/device/settings`, {
            body: [{ action: 'set_field', field: 'controller.auto_update', value: true }],
          });

          this.$set(this.device, 'settings', response);
        } catch (e) {
          this.$errors.handle(e, { ui_element: true });
          console.error(e);
          return;
        } finally {
          this.$set(this.auto_update, 'isLoading', false);
        }
        return;
      }

      // If setting auto update to false, ask for confirmation
      const vm = this;
      vm.$metronic.eventhub.emit('modals:layout:confirmation:open', {
        title: 'Disable auto update',
        message: 'Are you sure that you want to disable auto update?',
        buttonText: 'I\'m sure',
        async onSubmit () {
          vm.$set(vm.auto_update, 'isLoading', true);
          try {
            const response = await vm.$ws.put(`${Equipment.uri}/${vm.equipment._meta.guid}/device/settings`, {
              body: [{ action: 'set_field', field: 'controller.auto_update', value: false }],
            });

            vm.$set(vm.device, 'settings', response);
          } catch (e) {
            vm.$errors.handle(e, { ui_element: true });
            console.error(e);
          } finally {
            vm.$set(vm.auto_update, 'isLoading', false);
          }
        },
      });
    },

    async onSoftwarePinChange (value) {
      if (value === undefined || this.$auth.hasPermission('equipment.controller.update.pin') === false) {
        return;
      }

      const key = 'updates.P10241.pin';
      const mutations = [];
      if (value === 'delete') {
        mutations.push({
          action: 'array_remove_by_key_value',
          field: 'settings',
          key: 'key',
          key_value: key,
        });
      } else {
        mutations.push({
          action: 'array_set_item_by_key_value',
          field: 'settings',
          key: 'key',
          key_value: key,
          value: {
            key,
            payload: value || null,
          },
        });
      }

      if (mutations.length > 0) {
        this.$set(this.pinning, 'isLoading', true);
        try {
          await this.equipment.update(mutations);
        } catch (e) {
          this.$errors.handle(e, { ui_element: true });
          console.error(e);
        } finally {
          this.$set(this.pinning, 'isLoading', false);
        }
      }
    },

    async onRegularUpdateCycle () {
      const vm = this;
      vm.$metronic.eventhub.emit('modals:layout:confirmation:open', {
        title: 'Check & update',
        message: 'Are you sure that you want to check for updates?<br /> If a new update is available it will be installed automatically.',
        variant: 'info',
        buttonText: 'I\'m sure',
        async onSubmit () {
          try {
            await vm.$ws.put(`${Equipment.uri}/${vm.equipment._meta.guid}/controller/update`);
          } catch (e) {
            // If error is that there's no update found, just return and don't handle it
            // Because for the user, this will mean the same as if the update is already installed
            if (e && e.params && e.params.error === 'noUpdateFound') {
              return;
            }

            vm.$errors.handle(e, { ui_element: true });
            console.error(e);
          }
        },
      });
    },

    async onFullSoftwareTransfer () {
      const vm = this;
      vm.$metronic.eventhub.emit('modals:layout:confirmation:open', {
        title: 'Full software transfer',
        message: 'Are you sure that you want to execute a full software transfer?<br />This will force install the latest update.',
        variant: 'warning',
        buttonText: 'I\'m sure',
        async onSubmit () {
          try {
            await vm.$ws.put(`${Equipment.uri}/${vm.equipment._meta.guid}/controller/update`, {
              query: {
                forced: true,
              },
            });
          } catch (e) {
            // If error is that there's no update found, just return and don't handle it
            // Because for the user, this will mean the same as if the update is already installed
            if (e && e.params && e.params.error === 'noUpdateFound') {
              return;
            }

            vm.$errors.handle(e, { ui_element: true });
            console.error(e);
          }
        },
      });
    },

    onCustomFileTransfer () {
      const vm = this;
      this.$eventhub.emit('modals:equipment:custom-file-transfer:open', {
        title: 'Custom file transfer',

        async onSubmit (form) {
          if (!form || !form.file) {
            return;
          }

          const formData = new FormData();
          formData.append('file', form.file);

          try {
            await vm.$http.post(`${Equipment.uri}/${vm.equipment._meta.guid}/controller/update/custom`, {
              headers: {
                'Content-Type': 'multipart/form-data',
              },
              query: {
                forced: (form.forced === true),
              },
              body: formData,
            });

            // As a frontend fix/feature, we're going to set the controller auto update to false
            // The module does this for us, but we've to wait for the ping of the device to let the backend know
            vm.$set(vm.device, 'settings', {
              ...(vm.device.settings || {}),
              controller: {
                ...((vm.device.settings && vm.device.settings.controller) || {}),
                auto_update: false,
              },
            });
          } catch (e) {
            vm.$errors.handle(e, { ui_element: true });
            console.error(e);
          }
        },
      });
    },

    onRoute () {
      // Open removeResource modal when query 'remove' is set
      if (this.$route && this.$route.query) {
        if (typeof this.$route.query.update_cycle !== typeof undefined) {
          this.onRegularUpdateCycle();
          this.$router.replace({
            ...this.$router.currentRoute,
            query: {
              ...this.$router.currentRoute.query,
              update_cycle: undefined,
            },
          });
        }
      }
    },
  },
};
</script>
