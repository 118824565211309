/* eslint-disable camelcase */
/* eslint-disable lines-between-class-members */
import core from '@vedicium/core-vue';

import Abstract from '@/libs/classes/abstract';

export default class Update extends Abstract {
  static uri = '/v2/updates';

  // Scheme
  type = null;
  state = null;

  version = null;
  description = null;
  files = [];

  async download (options = {}) {
    const response = await core.http.get(`${Update.uri}/${this._meta.guid}/download`, { ...options }, {
      responseType: 'blob',
    });

    return response;
  }
}
