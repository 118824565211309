<template>
  <b-modal
    ref="modal"

    :title="(options.title || 'Custom file transfer')"

    size="lg"
    centered
  >
    <template v-slot:default>
      <form
        class="form"
        @submit.prevent="submitForm"
      >
        <div class="form-group">
          <label>File <sup>Required</sup></label>
          <div class="input-group">
            <div class="input-group-prepend">
              <span class="input-group-text w-50px">
                <i class="mx-auto">
                  <font-awesome-icon :icon="['fas', 'file-archive']" />
                </i>
              </span>
            </div>
            <label
              for="upload-file"
              :class="['form-control cursor-pointer', {
                'is-invalid': $v.form.file.$error,
                'disabled': isLoadingForm,
                'text-muted': !form.file
              }]"
              :disabled="isLoadingForm"
            >
              {{ form.file ? form.file.name : 'Choose file...' }}
            </label>
            <div class="input-group-append">
              <label class="input-group-text cursor-pointer" for="upload-file">
                Browse
              </label>
            </div>
          </div>
          <span class="form-text text-muted">The custom file / zip that's going to be transferred.</span>

          <!-- begin::File input -->
          <!-- File input isn't visible and is fixed by the label above -->
          <input
            id="upload-file"
            type="file"
            class="d-none"
            :disabled="isLoadingForm"
            @change="onFileChange($event.target.files)"
          />
          <!-- end::File input -->
        </div>

        <div class="row mt-n3 border-top pt-3">
          <div class="col-12 col-md-9">
            <h5 class="overflow-ellipsis">Force file transfer</h5>
            <span>Force the file transfer to the controller.</span>
          </div>
          <div class="col mt-3 mt-md-auto mb-md-auto text-right">
            <span :class="['switch', { 'switch-warning': form.forced === true }]">
              <label class="ml-auto">
                <input
                  type="checkbox"
                  name="select"
                  :checked="form.forced"
                  v-model="form.forced"
                >
                <span />
              </label>
            </span>
          </div>
        </div>
      </form>
    </template>

    <template v-slot:modal-footer>
      <button type="button" class="btn btn-secondary" :disabled="isLoadingForm" @click.prevent="close">Cancel</button>
      <button type="submit" class="btn btn-danger" :disabled="isLoadingForm" @click.prevent="submitForm">
        Transfer
      </button>
    </template>
  </b-modal>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import { validations } from '@vedicium/core-vue';
import { Mixins } from '@vedicium/metronic-vue';

export default {
  mixins: [Mixins.Modal],
  name: 'customFileTransferModal',
  data () {
    return {
      options: {},
      isLoadingForm: false,

      form: {
        file: null,
        forced: false,
      },
    };
  },
  validations: {
    form: {
      file: {
        required,
      },
    },
  },

  mounted () {
    // Listeners
    this.$eventhub.on('modals:equipment:custom-file-transfer:open', this.open);
    this.$eventhub.on('modals:equipment:custom-file-transfer:close', this.close);
  },
  beforeDestroy () {
    // Remove listeners
    this.$eventhub.off('modals:equipment:custom-file-transfer:open', this.open);
    this.$eventhub.off('modals:equipment:custom-file-transfer:close', this.close);
  },

  methods: {
    onFileChange (files = null) {
      if (!files || files.length === 0) {
        return;
      }

      const file = files[0];
      this.$set(this.form, 'file', file);
    },

    async validate () {
      await validations.validateArray([this.$v]);
    },
    async submitForm () {
      this.$set(this, 'isLoadingForm', true);

      try {
        await this.validate();

        if (this.options && typeof this.options.onSubmit === 'function') {
          await this.options.onSubmit(this.form);
        }
      } catch (e) {
        console.error(e);
        this.$errors.handle(e, { ui_element: true });
        return;
      } finally {
        this.$set(this, 'isLoadingForm', false);
      }

      this.close();
    },

    open (options = {}) {
      // Reset options
      this.$set(this, 'options', options || {});

      // Reset form
      this.$set(this, 'form', { file: null, forced: false });
      this.$v.$reset();

      this.$refs.modal.show();
    },
    close () {
      this.$refs.modal.hide();
    },

    async onShown () {
      await this.$nextTick();
    },
  },
};
</script>
